// 有 hash模式 和 history模式，通过下面代码控制： createWebHashHistory是hash模式，createwebHistory是history模式
import { createRouter, createWebHashHistory } from 'vue-router'

let routes = [
  { path: '/', name: 'login', component: () => import('@/pages/public/login'), meta: { title: '登陆' }, },
  { path: '/register', name: 'register', component: () => import('@/pages/public/register'), meta: { title: '注册' }, },
  { path: '/register-verify', name: 'registerVerify', component: () => import('@/pages/public/register-verify'), meta: { title: '注册验证' }, },
  { path: '/forget-pw', name: 'forget-pw', component: () => import('@/pages/public/forget-pw'), meta: { title: '忘记密码' }, },
  { path: '/security', name: 'security', component: () => import('@/pages/public/security'), meta: { title: '安全密码修改' }, },
  { path: '/author', name: '作者视图', component: () => import('@/pages/relation/author'), meta: { title: '作者' }, },
  { path: '/person', name: 'person', component: () => import('@/pages/relation/person'), meta: { title: '作者' }, },
  { path: '/relevance', name: 'relevance', component: () => import('@/pages/relation/relevance'), meta: { title: '关联图' }, },
  {
    path: '/user', name: 'user', component: () => import('@/pages/index'), meta: { title: '账户信息' }, redirect: '/user/personal', children: [
      { path: 'personal', name: 'personal', component: () => import('@/pages/user/personal'), meta: { title: '账户信息' }, },
      { path: 'personal-home-page', name: 'personal-home-page', component: () => import('@/pages/user/personal-home-page'), meta: { title: '个人主页' }, },
      // { path: 'resetpwd', name: 'resetpwd', component: () => import('@/pages/user/resetpwd'), meta: { title: '修必密码' }, },
    ]
  },
  {
    path: '/index', name: 'index', component: () => import('@/pages/index'), meta: { title: '首页', }, redirect: '/index/achievement', children: [
      {
        path: 'achievement', name: '成果管理', component: () => import('@/pages/achievement'), redirect: '/index/achievement/paper', top: true, children: [
          { path: 'paper', name: '论文', component: () => import('@/pages/achievement/paper'), group: "menu" },
          { path: 'patent', name: '专利', component: () => import('@/pages/achievement/patent'), group: "menu" },
          { path: 'software', name: '软件著作权', component: () => import('@/pages/achievement/software'), group: "menu" },
          { path: 'award', name: '个人获奖', component: () => import('@/pages/achievement/award'), group: "menu" },
          { path: 'stuaward', name: '学生获奖', component: () => import('@/pages/achievement/stuaward'), group: "menu" },
          { path: 'material', name: '继续教育材料', component: () => import('@/pages/achievement/material'), group: "menu" },
          { path: 'book', name: '著作', component: () => import('@/pages/achievement/book'), group: "menu" },
          { path: 'report', name: '新闻报道', component: () => import('@/pages/achievement/report'), group: "menu" },
          { path: 'certificate', name: '档案资料', component: () => import('@/pages/achievement/certificate'), group: "menu" },
          { path: 'socialservice', name: '社会服务', component: () => import('@/pages/achievement/socialservice'), group: "menu" },
          { path: 'meeting', name: '会议', component: () => import('@/pages/achievement/meeting'), group: "menu" },
          { path: 'teamwork', name: '工作组服务', component: () => import('@/pages/achievement/teamwork'), group: "menu" },
          { path: 'others', name: '其它', component: () => import('@/pages/achievement/others'), group: "menu" },
          { path: 'recycle', name: '回收站', component: () => import('@/pages/achievement/recycle'), group: "recycle" },
        ]
      },
      {
        path: 'project', name: '课题管理', component: () => import('@/pages/project'), redirect: '/index/project/direction', top: true, children: [
          { path: 'direction', name: '纵向课题', component: () => import('@/pages/project/direction'), group: "menu" },
          { path: 'transverse', name: '横向课题', component: () => import('@/pages/project/transverse'), group: "menu" },
          { path: 'teachproject', name: '教研教改', component: () => import('@/pages/project/teachproject'), group: "menu" },
          { path: 'stuproject', name: '学生项目', component: () => import('@/pages/project/stuproject'), group: "menu" },
          { path: 'recycle', name: '回收站', component: () => import('@/pages/project/recycle'), group: "recycle" },
        ]
      }
    ]
  },
  { path: '/index/share/:classify/:uuid', name: '分享', component: () => import('@/pages/achievement/share'), },
  { path: '/index/singleSingOn/:token', name: '一键登陆', component: () => import('@/pages/achievement/singleSingOn'), },
  { path: '/index/pdf-preview', name: 'pdf预览', component: () => import('@/pages/public/pdf-preview'), },
  { path: '/batchupload', name: '批量上传', component: () => import('@/pages/achievement/batchupload') },

]

const router = createRouter({
  history: createWebHashHistory(),
  mode: "hash",
  routes
})

router.beforeEach(async (to) => {
  if (to.name) { // 判断是否有标题
    document.title = to.name;
  }
  if (to.path === '/login' || to.path === '/logout') {
    // 清除localStorage中的用户数据
    localStorage.removeItem('user');
  }
});

export default router 