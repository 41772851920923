<template>
  <a-config-provider :locale="locale" :theme="{ token: { colorPrimary: '#00b38a' } }">
    <router-view />
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
export default {
  name: 'App',
  data () {
    return {
      locale: zhCN
    }
  },
  components: {

  }, mounted () {
    window.onpopstate = () => {
      console.log("限制回退")
      history.go(1);
    }
  }
}
</script>

<style lang="less">
@import "@/theme/style.less";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  // min-width: 1366px;
  width: 100%;
  height: 100%;

  input,
  div {
    border-radius: 2px;
  }
}
</style>
