import { createStore } from 'vuex'

export default createStore({
    state: {
        menuData:  [],
        user: {}
    },
    getters: {
        getMenuData(state) {
            return state.menuData;
        },
        getUser() {
            // if (state.user) return state.user;
            return localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : { classify: null };
        }
    },
    mutations: {
        setMenuData(state, value) {
            state.menuData = value;
        },
        setUser(state, value) {
            // console.log("mutations.setUser", value);
            state.user = value;
           
            localStorage.setItem("user", JSON.stringify(value));
        }
    },
    actions: {
        setMenuData(state, value) {
            // content.commit("setMenuData", value)
            state.menuData = value;
        },
        setUser(state, value) {
            // content.commit("setUser", value)
            state.user = value;
          
            localStorage.setItem("user", JSON.stringify(value));
            //console.log("actions.setUser", state.user)
        }
    },
    modules: {}
})